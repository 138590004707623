import AWS from 'aws-sdk';
import { loadStripe } from '@stripe/stripe-js';

// Initialize AWS Secrets Manager
const secretsManager = new AWS.SecretsManager();

let stripePromise;

// const getStripePublicKey = async () => {
//     try {
//         console.log("Attempting to retrieve secret 'stripePublic'...");
//         const data = await secretsManager.getSecretValue({ SecretId: 'stripePublic' }).promise();
//         console.log("Secret retrieved:", data); // Log the data received from Secrets Manager

//         if ('SecretString' in data) {
//             const secrets = JSON.parse(data.SecretString);
//             console.log("Parsed secrets:", secrets);
//             const stripePublicKey = secrets.STRIPE_PUBLIC_KEY;
//             console.log("Stripe Public Key:", stripePublicKey);
//             stripePromise = loadStripe(stripePublicKey);
//         } else {
//             throw new Error('SecretString not found');
//         }
//     } catch (err) {
//         console.error('Error fetching public key:', err);
//     }
// };

const getStripePublicKey = async () => {
    try {
        // Hardcoded test public key value (replace this with your actual key later)
        const stripePublicKey = 'pk_live_51Q4rUWGEzSHTpALyg2UNHIKPnjpuvt153FieiN9SETiINebkmfNY8oshMFqfHyLYJEOcejI1EZBnR7aJTeA0ck6W008HiXfQ6Q';
        console.log("Using hardcoded Stripe Public Key:", stripePublicKey);
        stripePromise = loadStripe(stripePublicKey);
    } catch (err) {
        console.error('Error fetching public key:', err);
    }
};

// Fetch the public key once when the module is loaded
getStripePublicKey();

export const handleBuyLicense = async (user) => {
    try {
        // Fixed amount for the license
        const amount = 50.00;

        // Use environment variable for the buy license API URL
        const buyLicenseApiUrl = process.env.REACT_APP_BUY_LICENSE_API_URL;

        // Call the backend to create a Stripe session
        const response = await fetch(buyLicenseApiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userId: user.username, amount }),
        });

        const result = await response.json();

        // Retrieve session ID and redirect to Stripe Checkout
        if (result && result.sessionId) {
            const stripe = await stripePromise;
            const { error } = await stripe.redirectToCheckout({ sessionId: result.sessionId });

            if (error) {
                console.error('Stripe Checkout error:', error);
            }
        } else {
            alert('Error initiating payment.');
        }
    } catch (error) {
        console.error('Error buying license:', error);
    }
};

export const navigateToRenewLicense = (navigate) => {
    navigate('/renewLicense');
};