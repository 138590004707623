import React, { useState } from "react";
import { Box, Grid, Typography, TextField, Button, Card, CardContent } from '@mui/material';
import StepperComponent from './StepperComponent.js';
import BasicHeader from './Header.js';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from "react-router-dom";
import { Authenticator } from '@aws-amplify/ui-react';
import { handleBuyLicense } from './CommonFunctions';

function LoginUser({ user }) {
    let navigate = useNavigate();
    const [isLicenseVerified, setIsLicenseVerified] = useState(false);
    const [licenseNumber, setLicenseNumber] = useState("");
    const [licenseDaysRemaining, setLicenseDaysRemaining] = useState(0);

    // Fetch API URLs from environment variables
    const validateLicenseApiUrl = process.env.REACT_APP_VALIDATE_LICENSE_API_URL;

    // License Verification
    const handleVerifyLicense = async (newLicenseNumber) => {
        try {
            console.log('Verifying license for user:', user.username, 'with license number:', newLicenseNumber);

            if (!validateLicenseApiUrl) {
                console.error("Error: validateLicenseApiUrl is undefined. Please check your environment variables.");
                return;
            }

            const response = await fetch(validateLicenseApiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId: user.username, licenseId: newLicenseNumber }),
            });

            console.log('License verification response status:', response.status);

            const result = await response.json().catch(() => {
                console.error('Failed to parse JSON from license verification response.');
                return null;
            });

            if (!result) {
                console.error('Error: Empty response received from license verification API.');
                alert('An error occurred while verifying the license.');
                return;
            }

            console.log('License verification result:', result);

            if (response.ok && result.message === 'License valid') {
                setIsLicenseVerified(true);
                alert('License verified successfully!');
                navigate('/profile'); // Redirect to profile
            } else {
                // Display error message returned by the backend
                alert(`Error: ${result.message}`);
                navigate('/renewLicense'); // Redirect to buy license page if needed
            }
        } catch (error) {
            console.error('Error verifying license:', error);
            alert('An unexpected error occurred while verifying the license. Please try again later.');
        }
    };


    const handleClick = () => {
        navigate('/profile');
    };

    return (
        <Authenticator>
            {({ signOut, user }) => (
                <>
                    <BasicHeader signOut={signOut} isAuthenticated={!!user} />
                    <Box sx={{ flexGrow: 1, padding: 2, backgroundColor: '#f9f9f9', minHeight: '100vh', marginTop: '80px' }}>
                        <Grid container spacing={3} justifyContent="center">
                            {/* <Grid item xs={12} sm={3}>
                                <StepperComponent />
                            </Grid> */}
                            <Grid item xs={12} sm={6}>
                                <Card sx={{ boxShadow: 3 }}>
                                    <CardContent>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            QuickApplyLoan
                                        </Typography>
                                        <Typography variant="subtitle1" color="text.secondary">
                                            Your user id is: <strong>{user.username}</strong>
                                        </Typography>
                                        {isLicenseVerified ? (
                                            <Box mt={2}>
                                                <Typography variant="body1" gutterBottom>
                                                    Your license is valid for {licenseDaysRemaining} more days.
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleClick}
                                                    sx={{ mt: 2 }}
                                                >
                                                    Go to Profile
                                                </Button>
                                                {licenseDaysRemaining <= 7 && (
                                                    <Box mt={2}>
                                                        <Button
                                                            variant="contained"
                                                            color="secondary"
                                                            onClick={() => handleBuyLicense(user)}
                                                        >
                                                            Renew License
                                                        </Button>
                                                    </Box>
                                                )}
                                            </Box>
                                        ) : (
                                            <Box mt={2}>
                                                <Typography variant="body2">Enter License Number:</Typography>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    value={licenseNumber}
                                                    onChange={(e) => setLicenseNumber(e.target.value)}
                                                    sx={{ mt: 1 }}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handleVerifyLicense(licenseNumber)}
                                                    sx={{ mt: 2, mx: 'auto', display: 'block' }}
                                                >
                                                    Verify License
                                                </Button>
                                                <Box sx={{ mt: 4, textAlign: 'center' }}>
                                                    <Typography variant="h6">Buy a New License</Typography>
                                                    <Typography variant="body2" gutterBottom>
                                                        The cost of a new license is:
                                                    </Typography>
                                                    <Box sx={{ mt: 2, display: 'inline-flex', alignItems: 'center', p: 1, border: '1px solid #ccc', borderRadius: '8px' }}>
                                                        <Typography variant="h5" sx={{ pr: 1 }}>$50.00</Typography>
                                                    </Box>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleBuyLicense(user)}
                                                        sx={{ mt: 3, display: 'block', mx: 'auto' }}
                                                    >
                                                        Buy License
                                                    </Button>
                                                </Box>
                                            </Box>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </Authenticator>
    );
}

export default withAuthenticator(LoginUser);
