import React from "react";

import { Stepper, Step, StepLabel, Button } from '@mui/material';

import { Amplify } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from './aws-exports.js';

Amplify.configure(awsExports);

function StepperComponent() {
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = ['Get License Info', 'Fill out forms', 'Get supporting documents', 'Submit application'];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div>
            <Stepper activeStep={activeStep} orientation="vertical" >
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {/* <div>
                {activeStep === steps.length ? (
                    <div>
                        <p>All steps completed</p>
                        <Button onClick={() => setActiveStep(0)}>Reset</Button>
                    </div>
                ) : (
                    <div>
                        <div>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                            >
                                Back
                            </Button>
                            <Button variant="contained" onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </div>
                    </div>
                )}
            </div> */}
        </div> 
    )
}

export default withAuthenticator(StepperComponent);